
import {useState, useEffect} from "react";
import CHLTSignupForm from '../ui-components/CHLTSignupForm.jsx';

import { Card, Flex, Image, Text } from "@aws-amplify/ui-react";

import ReactGA from "react-ga4";

function Signup() {

  const [submitStatus, setSubmitStatus] = useState(null);

  useEffect(() => {
    // Google Analytics 
    ReactGA.send({hitType: "pageview", page: "/signup", title: "Signup Form" });
  }, [])

  return (
    <>
      <Flex
      gap="0"
      direction="row"
      height="unset"
      wrap="wrap"
      justifyContent="center"
      alignItems="center"
      position="relative"
      padding="40px"
      backgroundColor="rgba(239,240,240,1)"
    >
      
      <Flex
        margin="12px"
        gap="8px"
        direction="column"
        position="relative"
        height="100%"
        justifyContent="center"
        alignItems="center"
        backgroundColor="rgba(255,255,255,1)"
        
      >
        <Card variation="outlined">
        {(() => {
          if (submitStatus === true)
          {
            // Log success to Google Analytics
            ReactGA.event({category: "submit", action: "success"});
            
            return (
              <>
              <p>Thank you for registering. You will receive an email shortly with details on how to access the Kite application.</p>
              <p>If you have not received an email within the next half-hour then please check your spam/junk mail folder. Alternatively you can contact us directly at support@weatherintelligence.global or call (07) 3369 3699.</p>
              </>)
          }
          else if (submitStatus === false) {
            // Log failure to Google Analytics
            ReactGA.event({category: "submit", action: "fail"});
            
            return "An error has occurred while logging your registration details.  Please contact us directly at support@weatherintelligence.global or call 07 3369 3699."
          }
          else {
            return (<>
              <div>Please enter the following details to register your Feedlot with Kite:</div>
                <CHLTSignupForm 
                  onSuccess={(fields)=>{setSubmitStatus(true)}}
                  onError={()=>{setSubmitStatus(false)}}
                />
              </>)
          }
          }
          )()}
        </Card>
      </Flex>
      <Flex
        margin="30px"
        gap="8px"
        direction="column"
        position="relative"
        height="100%"
        justifyContent="center"
        alignItems="center"
      >
       <Image
        alt="Kite logo"
        src="/Kite_logo.png"
        width="unset"
        height="120px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        margin="0px 0px 20px 0px"
        //objectFit="cover"
        overflow="clip"
      />
      <Image
        alt="KE logo"
        src="/KE_horiz_logo.png"
        width="unset"
        height="100px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        margin="0px 0px 0px 10px"
        //objectFit="cover"
        overflow="clip"
      />
      <Card
        width="320px"
        margin="50px 0px"
        backgroundColor="rgba(239,240,240,1)"
      >
        <Text>
          © Copyright 2023 Weather Intelligence (a Katestone company). <br/>
          All Rights Reserved.
        </Text>
        <br/>
        <Text>For details of our privacy policy please go to{" "}
          <a href="https://katestone.global/privacy">katestone.global/privacy</a>
        </Text>
      </Card>
      </Flex>
      
    </Flex>
    </>
  );
}

export default Signup;
 