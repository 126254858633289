/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Flex,
  Grid,
  SwitchField,
  TextAreaField,
  TextField,
} from "@aws-amplify/ui-react";
import { CHLTSignUp } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify";
export default function CHLTSignupForm(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    name: "",
    email: "",
    feedlot_name: "",
    company_name: "",
    address: "",
    phone: "",
    lat: "",
    lon: "",
    feedlot_size: "",
    have_weather_station: false,
  };
  const [name, setName] = React.useState(initialValues.name);
  const [email, setEmail] = React.useState(initialValues.email);
  const [feedlot_name, setFeedlot_name] = React.useState(
    initialValues.feedlot_name
  );
  const [company_name, setCompany_name] = React.useState(
    initialValues.company_name
  );
  const [address, setAddress] = React.useState(initialValues.address);
  const [phone, setPhone] = React.useState(initialValues.phone);
  const [lat, setLat] = React.useState(initialValues.lat);
  const [lon, setLon] = React.useState(initialValues.lon);
  const [feedlot_size, setFeedlot_size] = React.useState(
    initialValues.feedlot_size
  );
  const [have_weather_station, setHave_weather_station] = React.useState(
    initialValues.have_weather_station
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setName(initialValues.name);
    setEmail(initialValues.email);
    setFeedlot_name(initialValues.feedlot_name);
    setCompany_name(initialValues.company_name);
    setAddress(initialValues.address);
    setPhone(initialValues.phone);
    setLat(initialValues.lat);
    setLon(initialValues.lon);
    setFeedlot_size(initialValues.feedlot_size);
    setHave_weather_station(initialValues.have_weather_station);
    setErrors({});
  };
  const validations = {
    name: [{ type: "Required" }],
    email: [{ type: "Required" }, { type: "Email" }],
    feedlot_name: [{ type: "Required" }],
    company_name: [],
    address: [{ type: "Required" }],
    phone: [],
    lat: [],
    lon: [],
    feedlot_size: [{ type: "Required" }],
    have_weather_station: [{ type: "Required" }],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          name,
          email,
          feedlot_name,
          company_name,
          address,
          phone,
          lat,
          lon,
          feedlot_size,
          have_weather_station,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(new CHLTSignUp(modelFields));
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "CHLTSignupForm")}
      {...rest}
    >
      <TextField
        label={
          <span style={{ display: "inline-flex" }}>
            <span>Your Name</span>
            <span style={{ color: "red" }}>*</span>
          </span>
        }
        isRequired={true}
        isReadOnly={false}
        value={name}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name: value,
              email,
              feedlot_name,
              company_name,
              address,
              phone,
              lat,
              lon,
              feedlot_size,
              have_weather_station,
            };
            const result = onChange(modelFields);
            value = result?.name ?? value;
          }
          if (errors.name?.hasError) {
            runValidationTasks("name", value);
          }
          setName(value);
        }}
        onBlur={() => runValidationTasks("name", name)}
        errorMessage={errors.name?.errorMessage}
        hasError={errors.name?.hasError}
        {...getOverrideProps(overrides, "name")}
      ></TextField>
      <TextField
        label={
          <span style={{ display: "inline-flex" }}>
            <span>Email</span>
            <span style={{ color: "red" }}>*</span>
          </span>
        }
        isRequired={true}
        isReadOnly={false}
        value={email}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              email: value,
              feedlot_name,
              company_name,
              address,
              phone,
              lat,
              lon,
              feedlot_size,
              have_weather_station,
            };
            const result = onChange(modelFields);
            value = result?.email ?? value;
          }
          if (errors.email?.hasError) {
            runValidationTasks("email", value);
          }
          setEmail(value);
        }}
        onBlur={() => runValidationTasks("email", email)}
        errorMessage={errors.email?.errorMessage}
        hasError={errors.email?.hasError}
        {...getOverrideProps(overrides, "email")}
      ></TextField>
      <TextField
        label={
          <span style={{ display: "inline-flex" }}>
            <span>Feedlot Name</span>
            <span style={{ color: "red" }}>*</span>
          </span>
        }
        isRequired={true}
        isReadOnly={false}
        value={feedlot_name}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              email,
              feedlot_name: value,
              company_name,
              address,
              phone,
              lat,
              lon,
              feedlot_size,
              have_weather_station,
            };
            const result = onChange(modelFields);
            value = result?.feedlot_name ?? value;
          }
          if (errors.feedlot_name?.hasError) {
            runValidationTasks("feedlot_name", value);
          }
          setFeedlot_name(value);
        }}
        onBlur={() => runValidationTasks("feedlot_name", feedlot_name)}
        errorMessage={errors.feedlot_name?.errorMessage}
        hasError={errors.feedlot_name?.hasError}
        {...getOverrideProps(overrides, "feedlot_name")}
      ></TextField>
      <TextField
        label="Company Name (if different from Feedlot Name)"
        isRequired={false}
        isReadOnly={false}
        value={company_name}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              email,
              feedlot_name,
              company_name: value,
              address,
              phone,
              lat,
              lon,
              feedlot_size,
              have_weather_station,
            };
            const result = onChange(modelFields);
            value = result?.company_name ?? value;
          }
          if (errors.company_name?.hasError) {
            runValidationTasks("company_name", value);
          }
          setCompany_name(value);
        }}
        onBlur={() => runValidationTasks("company_name", company_name)}
        errorMessage={errors.company_name?.errorMessage}
        hasError={errors.company_name?.hasError}
        {...getOverrideProps(overrides, "company_name")}
      ></TextField>
      <TextAreaField
        label={
          <span style={{ display: "inline-flex" }}>
            <span>Address</span>
            <span style={{ color: "red" }}>*</span>
          </span>
        }
        isRequired={true}
        isReadOnly={false}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              email,
              feedlot_name,
              company_name,
              address: value,
              phone,
              lat,
              lon,
              feedlot_size,
              have_weather_station,
            };
            const result = onChange(modelFields);
            value = result?.address ?? value;
          }
          if (errors.address?.hasError) {
            runValidationTasks("address", value);
          }
          setAddress(value);
        }}
        onBlur={() => runValidationTasks("address", address)}
        errorMessage={errors.address?.errorMessage}
        hasError={errors.address?.hasError}
        {...getOverrideProps(overrides, "address")}
      ></TextAreaField>
      <TextField
        label="Phone"
        isRequired={false}
        isReadOnly={false}
        value={phone}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              email,
              feedlot_name,
              company_name,
              address,
              phone: value,
              lat,
              lon,
              feedlot_size,
              have_weather_station,
            };
            const result = onChange(modelFields);
            value = result?.phone ?? value;
          }
          if (errors.phone?.hasError) {
            runValidationTasks("phone", value);
          }
          setPhone(value);
        }}
        onBlur={() => runValidationTasks("phone", phone)}
        errorMessage={errors.phone?.errorMessage}
        hasError={errors.phone?.hasError}
        {...getOverrideProps(overrides, "phone")}
      ></TextField>
      <TextField
        label="Latitude"
        isRequired={false}
        isReadOnly={false}
        placeholder="e.g. -27.54"
        type="number"
        step="any"
        value={lat}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              name,
              email,
              feedlot_name,
              company_name,
              address,
              phone,
              lat: value,
              lon,
              feedlot_size,
              have_weather_station,
            };
            const result = onChange(modelFields);
            value = result?.lat ?? value;
          }
          if (errors.lat?.hasError) {
            runValidationTasks("lat", value);
          }
          setLat(value);
        }}
        onBlur={() => runValidationTasks("lat", lat)}
        errorMessage={errors.lat?.errorMessage}
        hasError={errors.lat?.hasError}
        {...getOverrideProps(overrides, "lat")}
      ></TextField>
      <TextField
        label="Longitude"
        isRequired={false}
        isReadOnly={false}
        placeholder="e.g. 127.53"
        type="number"
        step="any"
        value={lon}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              name,
              email,
              feedlot_name,
              company_name,
              address,
              phone,
              lat,
              lon: value,
              feedlot_size,
              have_weather_station,
            };
            const result = onChange(modelFields);
            value = result?.lon ?? value;
          }
          if (errors.lon?.hasError) {
            runValidationTasks("lon", value);
          }
          setLon(value);
        }}
        onBlur={() => runValidationTasks("lon", lon)}
        errorMessage={errors.lon?.errorMessage}
        hasError={errors.lon?.hasError}
        {...getOverrideProps(overrides, "lon")}
      ></TextField>
      <TextField
        label={
          <span style={{ display: "inline-flex" }}>
            <span>Feedlot Size (SCU)</span>
            <span style={{ color: "red" }}>*</span>
          </span>
        }
        isRequired={true}
        isReadOnly={false}
        type="number"
        step="any"
        value={feedlot_size}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              name,
              email,
              feedlot_name,
              company_name,
              address,
              phone,
              lat,
              lon,
              feedlot_size: value,
              have_weather_station,
            };
            const result = onChange(modelFields);
            value = result?.feedlot_size ?? value;
          }
          if (errors.feedlot_size?.hasError) {
            runValidationTasks("feedlot_size", value);
          }
          setFeedlot_size(value);
        }}
        onBlur={() => runValidationTasks("feedlot_size", feedlot_size)}
        errorMessage={errors.feedlot_size?.errorMessage}
        hasError={errors.feedlot_size?.hasError}
        {...getOverrideProps(overrides, "feedlot_size")}
      ></TextField>
      <SwitchField
        label="Do you have an Automatic Weather Station?"
        defaultChecked={false}
        isDisabled={false}
        isChecked={have_weather_station}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              name,
              email,
              feedlot_name,
              company_name,
              address,
              phone,
              lat,
              lon,
              feedlot_size,
              have_weather_station: value,
            };
            const result = onChange(modelFields);
            value = result?.have_weather_station ?? value;
          }
          if (errors.have_weather_station?.hasError) {
            runValidationTasks("have_weather_station", value);
          }
          setHave_weather_station(value);
        }}
        onBlur={() =>
          runValidationTasks("have_weather_station", have_weather_station)
        }
        errorMessage={errors.have_weather_station?.errorMessage}
        hasError={errors.have_weather_station?.hasError}
        {...getOverrideProps(overrides, "have_weather_station")}
      ></SwitchField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
