import './App.css';

import Signup from './pages/Signup';

import ReactGA from "react-ga4";

// Google Analytics 
ReactGA.initialize("G-K5HMLJK48H");

function App() {
  return (
      
    <div className="App">
      <Signup/>
    </div>
  );
}

export default App;
 